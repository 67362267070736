.destination {
  background-image: url("./images/image5.jpg");
  height: 600px;
  background-size: cover;
}

#img {
  max-width: fit-content;
  max-height: fit-content;
}

.destination h1 {
  font-size: clamp(2rem, 5vw, 5rem); /* Responsive font size */
  text-align: center;
  width: 100%;
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .destination {
    background-size: cover;

    margin-bottom: 0 !important;
    height: 400px;
    background-repeat: repeat-y;
  }

  .destination h1 {
    position: absolute !important;
    top: 45%;
    font-size: clamp(1.75rem, 4vw, 2.5rem); /* Smaller font size for mobile */
  }
}
