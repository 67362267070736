/* Login Container */
.login-container {
  background-color: #ed1c24;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Login Form */
.login-form {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  border: 2px solid #181344;
  max-width: 500px;
  width: 100%;
  box-shadow: 3px 3px 10px #181344;
}

/* Login Heading */
.login-heading {
  text-align: center;
  color: #181344;
}

/* Error Message */
.error-message {
  color: #ed1c24;
  text-align: center;
  margin-bottom: 10px;
}

/* Form Group */
.form-group {
  margin-bottom: 1rem;
}

/* Primary Button (Login) */
.btn-primary {
  background-color: #ed1c24;
  border-color: #ed1c24;
}

.btn-primary:hover {
  background-color: #c51e28;
  border-color: #c51e28;
}

/* Success Button (Register) */
.btn-success {
  background-color: #21a900;
  border-color: #21a900;
}

.btn-success:hover {
  background-color: #1b7c00;
  border-color: #1b7c00;
}

/* Toggle Login/Register */
.toggle-login {
  text-align: center;
  color: #181344;
  margin-top: 1rem;
}

.toggle-link {
  cursor: pointer;
  color: #ed1c24;
  font-weight: bold;
}

.toggle-link:hover {
  text-decoration: underline;
}

/* Modal Content */
.modal-content {
  padding: 20px;
  text-align: center;
}

.modal-content h2 {
  color: #ed1c24;
}

.modal-content p {
  margin-bottom: 20px;
}

.modal-content button {
  margin-top: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .login-form {
    padding: 1.5rem;
    margin: 1.2rem;
  }

  .form-group {
    margin-bottom: 0.75rem;
  }

  .toggle-login {
    font-size: 0.9rem;
  }

  .modal-content {
    padding: 15px;
  }
}
