/* Ensure z-index for nav */
nav {
  z-index: 100;
}

/* Navbar brand image styling */
.navbar-brand img {
  width: 250px;
  height: 75px;
}

.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1030;
  padding: 0 !important;
}

.fixed-top > .navbar {
  padding: 0 !important;
}

/* Navbar link styling */
.nav-link {
  color: #181344 !important;
}

.nav-link:hover,
.nav-link:focus {
  color: #ed1c24 !important;
}

/* Dropdown menu styling */
.dropdown-menu {
  background-color: #f8f9fa;
  display: none;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
}

/* Show dropdown on hover */
.nav-item.dropdown:hover .dropdown-menu {
  display: block;
  opacity: 1;
  visibility: visible;
}

/* Dropdown item styling */
.dropdown-item {
  color: #181344 !important;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #ed1c24 !important;
}

/* Adjust brand image size on smaller screens */
@media (max-width: 768px) {
  .navbar-brand img {
    width: 200px;
    height: 75px;
  }
}

@media (max-width: 768px) {
  .navbar > .container-fluid {
    padding-left: 10px;
    padding-right: 5px;
  }

  .navbar-nav .dropdown-menu {
    width: fit-content !important;
  }
}
