#wrapper {
  height: 100vh;
  width: 100vw !important;
  background-image: url("../../assets/images/star-sky.jpg");
  z-index: 1;
}

.flight-booking-form {
  background-color: transparent !important;
  padding: 20px;
}

.flight-booking-form h2 {
  color: #181344 !important;
  margin-bottom: 20px;
}

.flight-booking-form .form-label {
  color: #181344 !important;
}

.flight-booking-form .form-control,
.flight-booking-form .form-select {
  border: 1px solid #181344 !important;
  border-radius: 5px;
}

.flight-booking-form .form-control:focus,
.flight-booking-form .form-select:focus {
  border-color: #ed1c24;
  box-shadow: 0 0 0 0.2rem rgba(237, 28, 36, 0.25);
}

.flight-booking-form .btn-primary {
  background-color: #ed1c24;
  border-color: #ed1c24;
}

.flight-booking-form .btn-primary:hover {
  background-color: #c9141e;
  border-color: #c9141e;
}

/* btn */
.btn-outline-primary {
  background-color: #fff !important;
  color: #181344 !important;
  border: 1px solid #181344 !important;
}
#flight-btn {
  margin-right: 10px;
}
.btn-outline-primary:hover {
  background-color: #181344 !important;
  color: #fff !important;
}

.btn-outline-primary {
  background-color: #fff !important;
  color: #181344 !important;
  border: 1px solid #181344 !important;
}

@media (max-width: 500px) {
  .flight-booking-form {
    width: 100% !important;
  }
  #flight-btn {
    margin-bottom: 9px;
  }
  #booking-form {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 5px !important;
    padding: 7px;
  }

  .flight-booking-form {
    padding: 60px !important;
    max-width: 1500px;
    height: 600px !important;
    margin: auto;
    overflow: scroll !important;
  }
}

@media (max-width: 340px) {
  #flight-btn {
    margin-bottom: 9px;
  }
}

#booking-form {
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  margin: 5% auto !important;
}

#booking-form::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
  background-image: url("../../assets/images/background.jpg");
  background-size: cover;
  background-position: center;
  opacity: 0.98;
  z-index: -1;
  animation: moveBackground 10s linear infinite;
}

@keyframes moveBackground {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-5%, -15%);
  }
  100% {
    transform: translate(0, 0);
  }
}

@media (max-width: 768px) {
  #booking-form::before {
    background-image: url("../../assets/images/star-sky.jpg") !important;
  }

  #wrapper {
    background-image: none !important;

    padding: 10px;
  }
}

/* Larger screens */
@media (min-width: 1500px) {
  .flight-booking-form {
    padding: 60px !important;
    max-width: 1500px;
    height: 700px !important;
    margin: auto;
  }

  .flight-booking-form h2 {
    font-size: 2.5em;
    margin-bottom: 30px;
  }

  .form-check-label {
    font-size: 1.5em;
  }

  .flight-booking-form .form-label {
    font-size: 1.2em;
  }

  .flight-booking-form .form-control,
  .flight-booking-form .form-select {
    font-size: 1.5em;
    padding: 17px;
  }

  button#flight-btn.btn.btn-primary,
  button#hotel-btn.btn.btn-primary,
  button#flight-btn.btn.btn-outline-primary,
  button#hotel-btn.btn.btn-outline-primary {
    font-size: 0.4em !important;
  }

  .flight-booking-form .btn-primary,
  .flight-booking-form .btn-outline-primary {
    font-size: 1.2em;
    padding: 15px 15px;
  }
}
