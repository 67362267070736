.header-image {
  width: 100%;
  height: 600px !important;
  object-fit: cover !important;
}

.header-container {
  position: relative;
  text-align: center;
  color: white;
}

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: 700;
  font-size: 50px;
  text-shadow: 2px 2px 3px #000;
}

#badge {
  max-width: fit-content;
  font-size: 16px;
  flex-grow: 1;
  text-align: left;
}

@media (max-width: 768px) {
  .header-image {
    width: 100%;
    height: 400px !important;
    object-fit: cover !important;
  }
  .header-text {
    font-size: 35px !important;
  }

  .badge {
    font-size: 12px !important;
  }
}
